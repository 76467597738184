<script>
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'menu-add-cash-confirmation',
  components: {
    DrawerIconHeader,
    BaseButton,
    BaseAsidePage,
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      formatDate,
      formatDollar,
      PaymentMethodType,
      ewalletLabels,
    }
  },
  computed: {
    details() {
      return this.$router.currentRoute.params.details
    },
    type() {
      return this.$router.currentRoute.params.type
    },
    backRoute() {
      return this.$router.currentRoute.params.backRoute
    },
    status() {
      return this.details?.status
    },
    amount() {
      return this.details?.amount
    },
    number() {
      return this.details?.number
    },
    payee() {
      return this.details?.payee
    },
    payer() {
      return this.details?.payer
    },
    title() {
      switch (this.status) {
        case 'completed':
          return 'Payment Successful'
        case 'pending':
        default:
          return 'Pending Cash In'
      }
    },
    fromInfo() {
      const { from } = this.details

      if (!from) {
        return ''
      }

      switch (from.type) {
        case this.PaymentMethodType.eWallet:
          return this.ewalletLabels.ewalletCash
        case this.PaymentMethodType.creditCard:
          return `${from.name} ${from.brand}`
        case this.PaymentMethodType.bankAccount:
          return `${from.accountName}`
        default:
          return 'Payment Method'
      }
    },
    colorClasses() {
      switch (this.status) {
        case 'completed':
          return 'text-emerald-700'
        default:
          return 'text-blue-700'
      }
    },
  },
}
</script>

<template>
  <base-aside-page no-padding>
    <template #iconHeader>
      <drawer-icon-header
        :title="title"
        icon="success"
        :color-classes="colorClasses"
      />
    </template>
    <div class="flex h-full flex-col justify-between bg-white px-5 pb-[30px]">
      <div class="px-5">
        <div v-if="details.createdAt" class="flex pb-[15px]">
          <span class="inline-block w-32 flex-shrink-0 text-neutral-400">
            Date
          </span>
          <span class="font-bold text-eonx-neutral-800">
            {{ formatDate('daymonthyeartime', details.createdAt) }}
          </span>
        </div>
        <div v-if="number" class="flex pb-[15px]">
          <span class="inline-block w-32 flex-shrink-0 text-neutral-400">
            Transaction No.
          </span>
          <span class="font-bold text-eonx-neutral-800">
            {{ number }}
          </span>
        </div>
        <div class="flex pb-[15px]">
          <span class="inline-block w-32 flex-shrink-0 text-neutral-400">
            To
          </span>
          <span class="font-bold text-eonx-neutral-800">
            Top up {{ ewalletLabels.ewalletCash }}
          </span>
        </div>
        <div v-if="type !== 'cash-in'" class="flex pb-[15px]">
          <span class="inline-block w-32 flex-shrink-0 text-eonx-neutral-600">
            From
          </span>
          <span class="font-bold text-eonx-neutral-800">
            {{ fromInfo }}
          </span>
        </div>
        <div class="flex pb-[15px]">
          <span class="inline-block w-32 flex-shrink-0 text-eonx-neutral-600">
            Amount
          </span>
          <span class="font-bold text-eonx-neutral-800">
            {{ formatDollar(amount) }}
          </span>
        </div>
        <div v-if="payee" class="flex pb-[15px]">
          <span class="inline-block w-32 flex-shrink-0 text-neutral-400">
            Payee
          </span>
          <span class="font-bold text-eonx-neutral-800">
            {{ payee }}
          </span>
        </div>
        <div v-if="payer" class="flex pb-[15px]">
          <span class="inline-block w-32 flex-shrink-0 text-neutral-400">
            Payer
          </span>
          <span class="font-bold text-eonx-neutral-800">
            {{ payer }}
          </span>
        </div>
        <div
          v-if="type === 'pay-to' && status !== 'completed'"
          class="mt-2.5 leading-tight text-neutral-400"
        >
          Your payment is being processed, for extra detail's pertaining to the
          status of your payment order, please check
          {{ ewalletLabels.yourEwallet }} activity.
        </div>
      </div>
      <base-button
        type="primary"
        class="justify-self-end"
        @click="$router.replace(backRoute)"
      >
        Got it
      </base-button>
    </div>
  </base-aside-page>
</template>
